import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '@root/constants/token-key';
import { Refresh } from '@root/typings/generated';
import { sleep } from './time';
import { EXPIRE_TIME_KEY } from '../constants/token-key';

export const removeAuthCookies = async () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EXPIRE_TIME_KEY);
  await sleep(500);
  if (getTokens()) await removeAuthCookies();
};

export const getTokens = (): Tokens | undefined => {
  const token = localStorage.getItem(TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const expireTimeStr = localStorage.getItem(EXPIRE_TIME_KEY);
  const expireTime = new Date(expireTimeStr ? parseInt(expireTimeStr) : 0);
  return token || refreshToken
    ? {
        token: token || '',
        refreshToken: refreshToken || '',
        expireTime,
      }
    : undefined;
};

export const setAuthCookies = (tokens: Refresh): Tokens => {
  const { token, refreshToken } = tokens;
  const expTime = tokens.payload?.exp ? tokens.payload.exp * 1000 : undefined;

  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  if (expTime) {
    localStorage.setItem(EXPIRE_TIME_KEY, expTime.toString());
  }

  return {
    token,
    refreshToken,
    expireTime: expTime ? new Date(expTime) : undefined,
  };
};
